/* GERAL */
body {
	position:relative;
	padding-top: 65px;
	@include media($md) {padding-top:100px;}
	// min-height:  2000px;
	margin-bottom: 0;
	padding-bottom:0;

}
* {
	box-sizing: border-box;
}
.bg-site{
	position: fixed;
	z-index: -99999;
	width: 100%;
	height: calc(100vh + 200px);	
	background-image: linear-gradient(to bottom, #55746F 0%, #fff 40%);
	top: 0;
}
#topoHeader .areaImg{
	width: 100%;
	height: 600px;
	display: flex;
	vertical-align: middle;
	justify-content: center;
	.img{
	}

	
	// display: block;
	// max-height: 200px;
	// @include media($sm) {max-height:250px;}
	// @include media($md) {max-height:400px;}
}
#CarouselNews{
	.carousel-indicators{
		bottom: 0;
	}
	// .carousel-inner{
	// 	height: 350px;
	// 	.item{
	// 		height: 350px;
	// 	}
	// }
}
.carousel{
	position: relative;
	overflow: hidden;
	width: 100%;
	
	a{
		.next,.previous{
			position: absolute;
			font-size: 30px;
			@include media($sm) {
				font-size: 50px;

			}
			margin: 0 auto;
			top: 50%;


		}
	}
}


.header-home{
	.carousel-inner{
		height: 500px;
		.item{
			height: 500px;
		}
	}

	.carousel{
		overflow: visible;	
	}
	display: flex;
	position: relative;
	// max-height: 200px;
	// @include media($sm) {max-height:250px;}
	// @include media($md) {max-height:400px;}
	bottom: 0;
	left: 0;	
	top: -15px;
	box-shadow: 0 3px 8px rgba(0,0,0,0.4);
	@include media($md) {top:-10px;}
	figure{	
		text-align: center;
		height: 200px;
		width: 200px;
		position: absolute;
		z-index: 9;
		background: white;
		top: 50%;
		left: 50%;
		margin-top: -100px;
		margin-left: -100px;
		padding: 15px;
		border-radius: 50%;
		box-shadow: 5px 5px 6px rgba(0,0,0,0.5);
		
		height: 150px;
		width: 150px;
		margin-top: -75px;
		margin-left: -75px;
		@include media($sm) {
			height: 200px;
			width: 200px;
			margin-top: -75px;
			margin-left: -100px;
		}
		@include media($md) {
			height: 250px;
			width: 250px;
			margin-top: -100px;
			margin-left: -125px;
		}

		img{
			flex: 1 1 auto;
			width: auto !important;
			height: 100%;
		}
	}
	&>div{
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		
		height: 200px;
		@include media($sm) {height:250px;}
		@include media($md) {height:400px;}
	}
}


// a{
// 	color: $green_d1;
// 	&:hover{
// 		color: $green_l1;
// 	}
// }

a{
	color: $orange;

	&:hover{
		color: $orange_d1;
	}
}

h1,h2,h3,h4,h5,h6{
	color: $ct-default;
	text-transform: uppercase;
	font-weight:bold;
}
h1{

	font-size:2em;
}
h2{
	font-size:1.5em;
}
h3{
	font-size:1.17em;
}
h4{
	font-size:1em;
}
h5{
	font-size:0.83em;
}
h6{
	font-size:0.67em;
}
.logo-menu{
	height: 100%;
	height: auto;
}
.section-white{
	width: 100%;
	background-image: linear-gradient(50deg, $white 0%, darken($white, 10%) 30%, $white 100% );
	min-height: 400px;
}

figure{
	height:auto;
	width: 100%;
	position: relative;
	padding:0;
	
	img{
		width:100%;
		height:auto;
	}
	#logo-menu{
		height: 100%;
		width: auto;
	}
}
.slider-block{
	h1{
		padding-left: 30px;
		margin-top: 300px;
		padding-top: 50px;
		height: 100px;
		color: $gray_l2;

		background-image: linear-gradient(0deg, rgba($black,0.2) 0%, darken(rgba($black,0.2), 10%) 30%, rgba($black,0) 100% );

	}
	height:450px;
	// background-image: linear-gradient(0deg, $white 0%, darken($white, 10%) 30%, rgba($white,0) 100% );
}
.slider{
	background-size:cover;
	background-position: center;
	figure{
		padding: 10px;
	}
}
.main-title{
	color: $green_d1;
	border-bottom: 5px solid $green_d1;
	font-weight: bold;
	padding-bottom: $spacing-default-xs;
	display: block;
	margin-bottom: $spacing-default-xs;
	padding-top: $spacing-default-xs;
	margin-top:0;
	font-size: 2em;
	@include media($sm) {
		margin-bottom: $spacing-default-sm;
		font-size: 2.8em;
	}
	@include media($md) {
		margin-bottom: $spacing-default-md;
		font-size: 3.5em;
	}
}

.oval-bg{
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
	height: 150px;
	margin-top: -150px;
	@include media($xs) {
		height: 175px;
		margin-top: -100px;
	}
	@include media($sm) {
		height: 225px;
		margin-top: -150px;
	}
	div{
		position: relative;
		flex:1 1 auto;
		width: auto;
		height: 150px;
	}
	&>:last-child() {	
		width: 110%;
		flex: auto;
		background-color: $bg-color-default;
		border-radius: 50%;
		display: table;
		bottom: -100px;
	}
}
ul{
	padding-left:0;
	list-style: none;
	li{
		margin-bottom: $spacing-default-xs;
		ul{
			font-size: 16px;
		}
	}
}
.margin_bottom{
	margin-bottom: 25px;
	@include media($sm) {margin-bottom: 35px;}
}
.margin_bottom_default{
	margin-bottom: 35px;
	@include media($sm) {margin-bottom: 50px;}
}

.padding_bottom{
	padding-bottom: 25px;
	@include media($sm) {padding-bottom: 35px;}
}
.padding_bottom_default{
	padding-bottom: 35px;
	@include media($sm) {padding-bottom: 50px;}
}
.breadcrumb-line{
	margin-bottom: $line-height-computed;
	background-color: $breadcrumb-bg;
	width: 100%;
	.breadcrumb{
		margin-bottom: 0;
	}
}
.logo-bg-site{
	position: absolute;
	background-size: 100% auto;
	background-repeat: no-repeat;
	opacity: 0.15;
	height: 150px;
	width: 100px;
	bottom: 0px;
	right: 15px;
	z-index: -99;

	@include media($sm) {
		height: 200px;
		width: 150px;
		right: 50px;
		bottom: 5px;
	}

	@include media($md) {
		width: 200px;
		height: 250px;
		right: 50px;
		bottom: 15px;
		
	}
}
.carousel{
	h1{
		font-size: 1.5em;
	}
}
.carousel-control {
	    opacity: .0;
}

.flex-row{
	display: flex;
	flex-direction: column;
	@include media($md) {
    	flex-direction: row;
    }
}
.flex-center-text{
	display: flex;
    flex-direction: column;
    
    vertical-align: middle;
    align-items: center;
    align-self: center;
}



.col-xs-offset-0{margin-left: 0;}
@include media($sm) {.col-sm-offset-0{margin-left: 0;}}
@include media($md) {.col-md-offset-0{margin-left: 0;}}
@include media($lg) {.col-lg-offset-0{margin-left: 0;}}