/* MIXINS */

// Mixin to media queries
@mixin media($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}
@mixin media-max($width) {
    @media only screen and (max-width: $width) {
        @content;
    }
}

// Sticky footer styles
@mixin sticky-footer(
	$footer-height, 
	$footer-selector: unquote("#footer")) {
  
	html {
	  position: relative;
	  min-height: 100%;
	}
  
	body {
		margin-bottom: $footer-height + 20px; //20px espaçamento entre body e footer
  	}
  
	#{$footer-selector} {
		position: absolute;
		bottom: 0;
		width: 100%;  
		height: $footer-height;
	}
}