/* FOOTER */



#footer {
	display: block;
	position: relative;
	z-index: 999;
	box-shadow:0px -2px 10px 0px rgba($gray_d2, 0.8);
	padding: 10px 0;
	line-height:25px;	
	min-height: 60px;
	font-size: 16px;	
	background-color: $gray_l2;
	.por {
		float:right;
	}
}