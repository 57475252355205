// CONTATO
#cp-time-group{
    display: block;
    transition: 1s;
}
.cp-time-group{
    display: none;
}
.form-control-half{
    display: flex;
    flex-direction: column;
    @include media($sm) {
        flex-direction: row;
    }
}
.form-control-half .span-period{
    flex: 1 1 auto;
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    align-self: center;
}
.form-control-half > select,.form-control-half > div{
    flex: 1 1 auto;
}
.input-group-addon{
    min-width: 50px;
}
.input-group{
    display:flex;
    
}
.interna{
	background-color: $bg-color-default;	
	position: relative;
	padding-left: 10px;
	padding-right: 10px;
	// margin-bottom: 30px;
	// @include media($sm) {padding: 0 30px;}
	// @include media($md) {padding: 0 50px;}
	&>div{

		position: relative;

	}

}

.tags {
	border-left: 3px solid $green_d1;
	padding-left: 10px;

}

.interna{
	min-height: 500px;
	@extend .padding_bottom_default;

	h2{
		font-size: 1.8em;
		margin-top: 0;
	}
	figure{
		border: 3px solid $orange;
		padding-right: 20px;
		margin-bottom: 20px;
		overflow: hidden;
		padding: 10px;
		img{
			height: auto;
			width: 100%;

		}
		figcaption {
			margin-top: 10px;
			font-size: 0.9em;
			text-align: center;
		}
	}

	.internal-text{
		&>*{

			@extend .margin_bottom;
		}
		figure{
			margin-left: 0;;
			margin-right: 15px;
		}
		figure:nth-of-type(2n) {
			float:right; 
			margin-left: 15px;
			margin-right: 0;
		}
	}
	.internal-section-header{
		height: 100px;
		@include media($xs) {height: 150px;}
		@include media($sm) {height: 300px;}
		
		vertical-align: middle;
		border: 0 !important;
		margin-bottom: 35px;
	}
}
.internal-list {
	h2{
		margin-bottom: 15px;
	}
	&>li,&>div{
		display: inline-block;
		// flex-direction: row;
		// flex-wrap: nowrap;
		// justify-content: middle;
		// align-items: flex-start;
		// margin-bottom: 10px;
		@extend .margin_bottom;
		padding-top:0;
		margin-bottom:0;
		width: 100%;
		&>*{
			display: inline-block;
			position: relative;
		}
	}
	.hospedagem, .hospedagem>li{
		@include media($md) {margin-bottom: 0px;}


	}


}


.intenal-line-image{
	height: 150px;
	position: relative;
	width: 100%;
	display: none !important;
	@include media($md) {display: block !important;}
	&>div{
		height: 100%;
	}
}

.anchor-link{
	transition: 0.5s linear;
	margin-top: -100px!important;
	position: absolute !important;
	height: 0px;
	width:0px;
	&.first-anchor{
		margin-top: -100000px!important;

	}
}

.internal-list {
	&>li{
		padding:20px 5px;
		border-radius:0px;
		@include media($sm) {
			padding:30px 20px;
			border-radius:5px;
		}
		
	}
	&>:nth-child(2n+0) {
		background-color: rgba($gray_l2, 0.40);
	}	
	&>:last-child() {
		&>:last-of-type() {
			padding-bottom:0;
		}
		margin-bottom: 0;	
	}
}

.card{
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	position: relative;
	text-align:center;
	ul, figure{
		align-items: center;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
		position: initial;
	}
	ul{
		margin: 15px;
	}

	figure{
		border-radius: 50%;
		border: 3px solid $orange;
		overflow: hidden;
		padding: 0;
		height: 200px;
		width: 200px;
		min-width: 200px;

		@include media($md) {
			margin-bottom:0px;
			height: 150px;
			width: 150px;
			min-width: 150px;
		}
		img{
			height: 100%;
			width: intenal;

		}
	}
	.dark-bg{background-color: rgba($gray_l2, 0.40);}
	// &>:nth-child(2) {background-color: rgba($gray_l2, 0.40);}
	&>:nth-child(3n + 3) {
		// background-color: rgba($gray_l2, 0.40);
		li{flex-direction: row-reverse;}
	}
	&>li{
		border-radius: $br-default;
		margin-bottom: 0;
		padding: 20px;
		display: flex;
		justify-content: flex-start;

		flex-direction: column;
		align-items: center;
		width: 100%;
		@include media($sm) {
			justify-content: column;
			
		}
		@include media($md) {
			width:50%;
			flex-direction:row;
		}
		
	}
	&>:nth-child(2n + 0) {		
		flex-direction: column;
		@include media($md) {flex-direction: row-reverse; }
		// @include media-max($md - 1) {background-color: rgba($gray_l2,0.40);}
	}
	&>:nth-child(2n + 1) {		
		// @include media-max($md - 1) {background-color: $white;}
	}

}


.single-line{
	display:inline-block;
	ul{
		
		border-left: 3px solid $green_d1;
		padding-left: 15px;
		
	}
	&>li{
		padding: 20px;
		display:inline-block;
		
		width: 100%;
		
	}
	&>:nth-child(2n + 0) {	background-color: rgba($gray_l2,0.40);}
	&>:nth-child(2n + 1) {	background-color: $white; }
	figure{
		border: 3px solid $orange;
		margin-right:0px;
		@include media($md) {
			margin-bottom:0px;
			margin-right: 3px;
			
		}
		img{

			height: auto;
			width: 100%;
		}
	}

}
.sobre, .mais-fotos-sobre{
	figure:nth-of-type(2n) {
		background-color: rgba($gray_l2,0.40);

	}
}


textarea {
	min-height: 200px;
}
.slide-txt-left {margin: 0 15px 15px 0;}
.slide-txt-right {margin: 0 0 15px 15px;}