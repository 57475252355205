/* VARIÁVEIS */

//====================
// COLOR PALLETS
//====================
	//--------------------
	//       green
	//--------------------
	
	
	$green:#55746F;
	$orange:#EF7F00;

	$green_l2:lighten($green,50%);
	$green_l1:lighten($green,20%);
	$green_d1:darken($green,10%);
	$green_d2:darken($green,20%);

	$orange_l3:lighten($orange,50%);
	$orange_l2:lighten($orange,30%);
	$orange_l1:lighten($orange,20%);
	$orange_d1:darken($orange,10%);
	$orange_d2:darken($orange,20%);

	//--------------------
	//     gray
	//--------------------
	
	$gray_l5: 	#FEFEFE;
	$gray_l4: 	#FDFDFD;
	$gray_l3: 	#F2F2F2;
	$gray_l2: 	#E3E3E3;
	$gray_l1: 	#D5D5D5;
	
	$gray:	#A9A9A9; // Main gray
	
	$gray_d1:	#A1A1A1;
	$gray_d2:	#7D7D7D;
	$gray_d3:	#4A4949;
	

	//--------------------
	//     black
	//--------------------
	$black:		#000000;

	//--------------------
	//     white
	//--------------------
	$white:		#FFFFFF;

$text-light: $gray_l3;
$bg-dark: rgba($green_d2,0.25);



$color-border: rgba($green,0.5);

$cor_font_link: $green_d2;
$cor_font_link_hover: $green;

// ----------
// Tipografia Personalizada
// ----------
 $font-family-base:"verdana", arial, serif;
$font-family-menu:"verdana", arial, serif;
$font-family-default: "verdana", sans-serif;
$font-size-base: 18px;

// ----------
// Viewport
// Para uso no mixin media
// ----------
$xs: 400px;
$sm: 768px;
$md: 992px;
$lg: 1200px;

/* Sobrescrição de variáveis do bootstrap */
//== Colors
// $brand-primary:;
// $brand-success:;
// $brand-info:;
// $brand-warning:;
// $brand-danger:;

//== Scaffolding
//$body-bg:;
//$link-color:;
//$link-hover-color:;
$link-hover-decoration: none;

//== Grid system
$grid-float-breakpoint:	$md; //O menu para tablet vai se comportar como a versão mobile.

//== Navbar

// Basics of a navbar
// $navbar-height:                    50px !default;
// $navbar-margin-bottom:             $line-height-computed !default;
// $navbar-border-radius:             $border-radius-base !default;
// $navbar-padding-horizontal:        floor(($grid-gutter-width / 2)) !default;
// $navbar-padding-vertical:          (($navbar-height - $line-height-computed) / 2) !default;
// $navbar-collapse-max-height:       340px !default;

// $navbar-default-color:             #777 !default;
// $navbar-default-bg:                #f8f8f8 !default;
// $navbar-default-border:            darken($navbar-default-bg, 6.5%) !default;

// Navbar links
// $navbar-default-link-color:                #777 !default;
// $navbar-default-link-hover-bg:             transparent !default;
// $navbar-default-link-active-color:         #555 !default;
// $navbar-default-link-active-bg:            darken($navbar-default-bg, 6.5%) !default;
// $navbar-default-link-disabled-color:       #ccc !default;
// $navbar-default-link-disabled-bg:          transparent !default;



// $navbar-height:			36px;
$navbar-margin-bottom:	0;
$navbar-border-radius:	0;
$navbar-default-bg:	$gray_l4;
// $navbar-default-border:	darken($navbar-default-bg, 6.5%) !default;

// Navbar links
$navbar-default-link-color:  $green;
// $navbar-default-link-hover-bg: $gray_l2;
$navbar-default-link-hover-color: $green_d1;
// $navbar-default-link-active-bg: ;

// Navbar toggle
$navbar-default-toggle-hover-bg:           transparent;
$navbar-default-toggle-icon-bar-bg:        $orange;
$navbar-default-toggle-border-color:       transparent;







/* FIM - sobrescrição de variáveis do bootstrap */

//Extras

$padding-default: 15px;



$br-default: 5px;
$ct-default: $green;
$bg-color-default:$gray_l5;
$mb-default: 50px;
$color-default: $ct-default;
$spacing-default-xs: 10px;	
$spacing-default-sm: 20px;	
$spacing-default-md: 30px;
$spacing-default-lg: 50px;
