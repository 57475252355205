/* HEADER */

.slider-block{
	background-color:$navbar-default-bg;
	overflow: hidden;
	position: relative;
	display: block;
	margin-bottom:$mb-default;
	.slick-dotted.slick-slider{margin-bottom: 0;}
	.regular {
		width: 100%;
		overflow: hidden;
		max-height: 650px;
		// &>div{
		// 	width: 100%;
		// 	height: 100%;
		// }
	}
	.convenios{
		margin: 20px 0;
		overflow: hidden;
		width:100%;
		max-height: 500px;		
		figure{
			padding:0 10px;
		}
		img {
			width: 100%;
		}
	}
	.slick-track{
		width: 100% !important; 
	}
	.slick-slide {
		width: 100%;
	}
	.slick-list{
		height: 500px;
	}
}



.slick-dots{
	bottom:10px;
}
.slick-dots li button:before{color: $color-default;}

.slick-dots li.slick-active button:before{color: $orange;}

