//=====================
// MIXINS to
//=====================

// Mixin to media queries
@mixin Media($width) {	@media only screen and (min-width: $width) {@content;}}

// Mixin to media queries
@mixin MediaMax($width) { @media only screen and (max-width: $width) {@content;}}

// Transition
@mixin Transition($property:all, $time:300, $delay:0, $effect:linear){
	transition: #{$property} #{$time}ms #{$effect} #{$delay}ms;
}

//===============================
// cleaning paddings and margins
//===============================

.no-padding{padding:0}
.no-padding-top{padding-top:0}
.no-padding-right{padding-right:0}
.no-padding-bottom{padding-bottom:0}
.no-padding-left{padding-left:0}
.no-padding-horizontal{padding-left: 0; padding-right: 0;}
.no-padding-verical{padding-top: 0; padding-bottom: 0;}

.no-margin{margin:0}
.no-margin-top{margin-top:0}
.no-margin-right{margin-right:0}
.no-margin-bottom{margin-bottom:0}
.no-margin-left{margin-left:0}
.no-margin-horizontal{margin-left:0;margin-right:0;}
.no-margin-verical{margin-top:0; margin-bottom:0;}

//======================
// IMG BACKGROUND COVER
//======================
%bg-cover{
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
}
$border-radius-base: 5px !default;
%radius-dafault{border-radius: $border-radius-base;}
// Image Backgrounds without bord-radius
.bg-cover{@extend %bg-cover;}
.bg-cover-10  {@extend %bg-cover;padding-bottom:  10%;}
.bg-cover-20  {@extend %bg-cover;padding-bottom:  20%;}
.bg-cover-30  {@extend %bg-cover;padding-bottom:  30%;}
.bg-cover-40  {@extend %bg-cover;padding-bottom:  40%;}
.bg-cover-50  {@extend %bg-cover;padding-bottom:  50%;}
.bg-cover-60  {@extend %bg-cover;padding-bottom:  60%;}
.bg-cover-70  {@extend %bg-cover;padding-bottom:  70%;}
.bg-cover-80  {@extend %bg-cover;padding-bottom:  80%;}
.bg-cover-90  {@extend %bg-cover;padding-bottom:  90%;}
.bg-cover-100 {@extend %bg-cover;padding-bottom: 100%;}
// Image Backgrounds with bord-radius
.bg-cover-10-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  10%;}
.bg-cover-20-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  20%;}
.bg-cover-30-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  30%;}
.bg-cover-40-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  40%;}
.bg-cover-50-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  50%;}
.bg-cover-60-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  60%;}
.bg-cover-70-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  70%;}
.bg-cover-80-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  80%;}
.bg-cover-90-r  {@extend %radius-dafault;@extend %bg-cover;padding-bottom:  90%;}
.bg-cover-100-r {@extend %radius-dafault;@extend %bg-cover;padding-bottom: 100%;}

.bg-cover-fixed{
	@extend %bg-cover;
	background-attachment: fixed;
	background-position:fixed;
	z-index: -900;
	height: 100%;

}
//======================
// HIDDEN ELEMENTS 
//======================

// Classes to hide elements, used to use with btn show-more-items
.hidden-it-a,.hidden-it-b,.hidden-it-c,.hidden-it-d,.hidden-it-e,.hidden-it-f,.hidden-it-g,
.hidden-it-h,.hidden-it-i,.hidden-it-j,.hidden-it-k,.hidden-it-l,.hidden-it-m,.hidden-it-n,
.hidden-it-o,.hidden-it-p,.hidden-it-q,.hidden-it-r,.hidden-it-s,.hidden-it-t,.hidden-it-u,
.hidden-it-x,.hidden-it-w,.hidden-it-y,.hidden-it-z{display: none;}

//======================
// TEXT
//======================
.text-left{text-align:left;}
.text-center{text-align:center;}
.text-right{text-align:right;}

.title-left{text-align:left;width:100%;}
.title-center{text-align:center;width:100%;}
.title-right{text-align:right;width:100%;}



.items-centered{display:flex;justify-content:center;position:relative;}