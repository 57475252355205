
@keyframes opacity{ 
	0%{opacity:0;}
	100%{opacity:1;}
}



@keyframes display-opacity{ 
	0%{
		display:none;
		opacity:0;
	}
	800%{
		display:block;
	}
	100%{
		display:block;
		opacity: 1;
	}
}


@keyframes opacity-fade{ 
	0%{opacity:1;}
	100%{opacity:0.7;}
}

@keyframes scale2x{
	0%{transform: scale(2);}
	100%{transform: scale(1);}
}
@keyframes scale-low{
	0%{transform: scale(1);}
	50%{transform: scale(1.05);}
	100%{transform: scale(1);}
}

@keyframes logo-to-png {
	0%{content: url('../../dist/img/logos/logo-anim.gif');}
	100%{content: url('../../dist/img/logos/logo.png');}
}

@keyframes logo-menu { 
	0% {
		left:0;
		opacity: 0;
		height: 100vh;
		width: 100%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	20% {
		left:0;
		opacity: 1;
		height: 100vh;
		width: 100%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	40% {
		left:0;
		opacity: 1;
		height: 100vh;
		width: 100%;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	15%{opacity: 1;}
	70%{opacity:1;}
	84.99% {position: absolute;	}
	85%{
		opacity: 0;
		float: left;
		padding: 10px;
		font-size: 20px;
		line-height: 22px;
		height: 100px;
		width: 100px;
		position: relative;
		display: block;
	}
	90%{
		display: block;
		opacity:0;
		float: left;
		padding: 10px;
		font-size: 20px;
		line-height: 22px;
		height: 100px;
		width: 100px;
		position: relative;
	}
	100%{
		display: block;
		opacity:1;
		float: left;
		padding: 10px;
		font-size: 20px;
		line-height: 22px;
		height: 100px;
		width: 100px;
		position: relative;
	}
}