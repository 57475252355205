/* NAV */
#navbar{
	a{
		margin-top:3px;
		@include media($md){padding: 5px 8.5px;}
		@include media($lg){padding: 5px 15px;}
	}
	.active{
		&>a{
			border-radius: $br-default;
			color: $gray_l3;
			background-color: $orange;
		}
	}
	@include media($md){
		float: right;
		.nav{
			float:right;
		}
	}
	.dropdown-menu {
		@include media($md){top: 40px;}
		li >a{color:$navbar-default-link-color;}
		li >a:hover{
			color:$navbar-default-link-color;
			background:$navbar-default-link-hover-bg;
		}
	}
}
.navbar-default {
	font-size:1em;
	box-shadow:0px 5px 10px 0px rgba($gray_d2, 0.8);
	background-color:rgba($white, 0.95);
}
.brand {
	float: left;
	opacity:1;
	font-size: 20px;
	line-height: 22px;
	top:0;
	left: 0;
	max-width: 70px;
	padding: 10px;
	display: block;
	position: relative;
	img{max-height: 30px;}
	@include media($md){img{max-height: 69px;}}
}
.navbar-nav{
	padding:10px 0;
	@include media($md){padding:20px 0;}
}
.navbar-nav a {
	text-decoration: none;
	color: $orange;
	-webkit-transition: .5s color linear 0s;
}
.navbar-nav a:after {
	content: '';
	display: block;
	border-bottom: 1px solid $orange;
	width: 0;
	transition: 0.7s;
}
.navbar-nav a:hover:after {
	width: 100%;
}